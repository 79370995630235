import React, { useState, useRef } from 'react'
import { graphql } from 'gatsby'
import { FixedObject } from 'gatsby-image'
import {
  Wrapper,
  Container,
  ImageContainer,
  TextContainer,
  VideoContainer,
} from './style'
import Modal from 'src/components/common/Modal'

export type HeroProps = {
  title: string
  images: FixedObject[]
  youtubeVideoId
  youtubeVideoButtonText
}

export default function Hero(props: HeroProps) {
  const [displayYoutubeVideo, setDisplayYoutubeVideo] = useState<boolean>(false)

  const youtubeVideoIframe = useRef(null)

  function closeYoutubeVideo() {
    youtubeVideoIframe &&
      youtubeVideoIframe.current &&
      youtubeVideoIframe.current.contentWindow.postMessage(
        '{"event":"command","func":"stopVideo","args":""}',
        '*'
      )

    setDisplayYoutubeVideo(false)
  }

  return (
    <Wrapper>
      <Container>
        <ImageContainer>
          {props.images &&
            props.images.map((fixed: FixedObject, i) => {
              return (
                <img
                  key={i}
                  src={fixed.src}
                  srcSet={fixed.srcSet}
                  alt=""
                  data-aos="fade-down"
                />
              )
            })}
        </ImageContainer>
        <TextContainer>{props.title && <h3>{props.title}</h3>}</TextContainer>
        {props.youtubeVideoId && props.youtubeVideoButtonText && (
          <VideoContainer>
            <Modal open={displayYoutubeVideo} handleClose={closeYoutubeVideo}>
              <iframe
                ref={youtubeVideoIframe}
                width="100%"
                height="500"
                src={`https://www.youtube.com/embed/${props.youtubeVideoId}?enablejsapi=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Modal>
            <button
              onClick={() => {
                setDisplayYoutubeVideo(true)
              }}
            >
              {props.youtubeVideoButtonText}
            </button>
          </VideoContainer>
        )}
      </Container>
    </Wrapper>
  )
}

export const typename = 'GraphCMS_Hero'

export const fragment = graphql`
  fragment defaultHero on GraphCMS_Hero {
    __typename
    id
    flatData {
      title
      image {
        url
        imageFile {
          childImageSharp {
            fixed(height: 520) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      youtubeVideoId
      youtubeVideoButtonText
    }
  }
`

export function renderHero(hero: any) {
  const props = {
    title: hero.flatData?.title,
    images: (hero.flatData?.image || [])
      .map(image => {
        return image.imageFile?.childImageSharp?.fixed
      })
      .filter(image => !!image),
    youtubeVideoId: hero.flatData.youtubeVideoId,
    youtubeVideoButtonText: hero.flatData.youtubeVideoButtonText,
  }

  return <Hero key={hero.id} {...props} />
}

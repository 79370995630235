import React from 'react'
import { typename as postTypename, renderPost } from './Post'
import { typename as listTypename, renderList } from './List'
import { typename as heroTypename, renderHero } from './Hero'
import {
  typename as contactFormTypename,
  renderContactForm,
} from './ContactForm'
import { typename as bannerTypename, renderBanner } from './Banner'

type NodeProps = {
  __typename: string
}

export default function CmsComponent(props: NodeProps) {
  switch (props.__typename) {
    case postTypename:
      return renderPost(props)
    case listTypename:
      return renderList(props)
    case heroTypename:
      return renderHero(props)
    case contactFormTypename:
      return renderContactForm(props)
    case bannerTypename:
      return renderBanner(props)
    default:
      return <></>
  }
}

import axios from 'axios'

export type SubmitContactFormPostBody = {
  freeDemoOptIn: boolean
  contactMeOptIn: boolean
  campaign1OptIn: boolean
  name: string
  company: string
  email: string
  message: string
  terms: string
}

export function SubmitContactForm(
  url: string,
  body: SubmitContactFormPostBody
) {
  return axios.get(url).then(res => {
    if (!res || !res.data || !res.data.token) {
      throw 'No token was returned'
    }

    return axios.post(url, {
      token: res.data.token,
      ...body,
    })
  })
}

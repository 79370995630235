import React from 'react'
import { graphql } from 'gatsby'
import { FixedObject } from 'gatsby-image'
import { Wrapper, Container, Item } from './style'
import Html from 'src/components/Html'
import CheckImg from './check.png'

export type ListProps = {
  items: {
    title: string
    body: string
  }[]
}

export default function List(props: any) {
  const w = 100 / (props.items.length || 1)

  return (
    <Wrapper>
      <Container>
        {props.items.map(item => {
          return (
            <Item key={item.title} w={w}>
              <h2>
                <img src={CheckImg} alt="" />
                {item.title}
              </h2>
              <p>{item.body}</p>
            </Item>
          )
        })}
      </Container>
    </Wrapper>
  )
}

export const typename = 'GraphCMS_List'

export const fragment = graphql`
  fragment defaultList on GraphCMS_List {
    __typename
    id
    flatData {
      listItems {
        title
        body
      }
    }
  }
`

export function renderList(content: any) {
  const props = {
    items: content.flatData.listItems || [],
  }

  return <List key={content.id} {...props} />
}

import React from 'react'
import { FixedObject } from 'gatsby-image'
import { Wrapper, Logo, Text, Images } from './style'
import LogoImg from 'src/images/logo.png'

type FooterProps = {
  text: string
  images: {
    fixed: FixedObject[]
    alt: string
  }
}

export default function Footer(props: FooterProps) {
  return (
    <Wrapper>
      <Logo>
        <img src={LogoImg} alt="Logo" />
      </Logo>
      <Text>{props.text}</Text>
      <Images>
        {props.images &&
          props.images.map(({ fixed, alt, url }, i) => {
            const img = (
              <img key={i} src={fixed.src} srcSet={fixed.srcSet} alt={alt} />
            )

            if (url) {
              return (
                <a key={i} href={url} target="_blank" rel="noopener">
                  {img}
                </a>
              )
            }

            return img
          })}
      </Images>
    </Wrapper>
  )
}

import React, { useState, useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import {
  Wrapper,
  ContainerWide,
  Container,
  Field,
  CheckboxFieldSize,
  CheckboxField,
  CheckboxFieldText,
  ExpandTermsButton,
  SubmitButtonContainer,
  Error,
  AnchorLink,
} from './style'
import { SubmitContactForm } from './service'
import Html from 'src/components/common/Html'

enum SubmitStatus {
  NotSubmitted = 0,
  SubmitFail = -1,
  SubmitSuccess = 1,
}

function clickSubmit(
  url: string,
  freeDemoOptIn: boolean,
  contactMeOptIn: boolean,
  campaign1OptIn: boolean,
  name: string,
  company: string,
  email: string,
  message: string,
  terms: string,
  setSubmitting: Function,
  setSubmitStatus: Function
) {
  setSubmitting(true)
  setSubmitStatus(SubmitStatus.NotSubmitted)

  SubmitContactForm(url, {
    freeDemoOptIn,
    contactMeOptIn,
    campaign1OptIn,
    name,
    company,
    email,
    message,
    terms,
  })
    .then(res => {
      setSubmitStatus(SubmitStatus.SubmitSuccess)
    })
    .catch(error => {
      console.error(error)

      setSubmitStatus(SubmitStatus.SubmitFail)
    })
    .then(() => {
      setSubmitting(false)
    })
}

export default function ContactForm({
  url,
  anchorButtonText,
  title,
  description,
  pdfs,
  secondDescription,
  freeDemoOptInLabel,
  contactMeOptInLabel,
  campaign1OptInLabel,
  nameLabel,
  namePlaceholder,
  companyLabel,
  companyPlaceholder,
  emailLabel,
  emailPlaceholder,
  messageLabel,
  messagePlaceholder,
  submitText,
  submitSuccess,
  submitFail,
  terms,
  expandTerms,
}) {
  const [freeDemoOptIn, setFreeDemoOptIn] = useState<boolean>(true)
  const [contactMeOptIn, setContactMeOptIn] = useState<boolean>(true)
  const [campaign1OptIn, setCampaign1OptIn] = useState<boolean>(true)
  const [name, setName] = useState<string>('')
  const [company, setCompany] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [agreed, setAgreed] = useState<boolean>(false)
  const [expanded, setExpanded] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [submitStatus, setSubmitStatus] = useState<SubmitStatus>(
    SubmitStatus.NotSubmitted
  )

  const inputDisabled =
    submitting || submitStatus === SubmitStatus.SubmitSuccess

  const anchor = useRef(null)

  useEffect(() => {
    if (!anchor.current) {
      return
    }

    const nav = document.getElementById('nav-container')

    if (!nav) {
      return
    }

    nav.appendChild(anchor.current)

    return () => {
      if (anchor.current) {
        anchor.current.remove()
      }
    }
  }, [])

  return (
    <>
      <Wrapper id="contact-us">
        <ContainerWide>
          <h2>{title}</h2>
          <Html input={description} />
          {pdfs &&
            pdfs.map(pdf => {
              return (
                <p key={pdf.staticUrl}>
                  <a href={pdf.staticUrl} target="_blank">
                    {pdf.metadata?.name || pdf.staticUrl}
                  </a>
                </p>
              )
            })}
          <Html input={secondDescription} />
        </ContainerWide>
        <Container>
          <CheckboxField size={CheckboxFieldSize.Large}>
            <input
              type="checkbox"
              disabled={inputDisabled}
              onChange={e => setFreeDemoOptIn(e.target.checked)}
              checked={freeDemoOptIn}
            />
            <CheckboxFieldText expanded={true} largeText={true}>
              {freeDemoOptInLabel && <p>{freeDemoOptInLabel}</p>}
            </CheckboxFieldText>
          </CheckboxField>
          <CheckboxField size={CheckboxFieldSize.Large}>
            <input
              type="checkbox"
              disabled={inputDisabled}
              onChange={e => setContactMeOptIn(e.target.checked)}
              checked={contactMeOptIn}
            />
            <CheckboxFieldText expanded={true} largeText={true}>
              {contactMeOptInLabel && <p>{contactMeOptInLabel}</p>}
            </CheckboxFieldText>
          </CheckboxField>
          <CheckboxField size={CheckboxFieldSize.Large}>
            <input
              type="checkbox"
              disabled={inputDisabled}
              onChange={e => setCampaign1OptIn(e.target.checked)}
              checked={campaign1OptIn}
            />
            <CheckboxFieldText expanded={true} largeText={true}>
              {campaign1OptInLabel && <p>{campaign1OptInLabel}</p>}
            </CheckboxFieldText>
          </CheckboxField>
          <Field>
            <label>{nameLabel}*</label>
            <input
              type="text"
              autoComplete="name"
              placeholder={namePlaceholder}
              disabled={inputDisabled}
              onChange={e => setName(e.target.value)}
            />
          </Field>
          <Field>
            <label>{companyLabel}</label>
            <input
              type="text"
              autoComplete="organization"
              placeholder={companyPlaceholder}
              disabled={inputDisabled}
              onChange={e => setCompany(e.target.value)}
            />
          </Field>
          <Field>
            <label>{emailLabel}*</label>
            <input
              type="email"
              autoComplete="email"
              placeholder={emailPlaceholder}
              disabled={inputDisabled}
              onChange={e => setEmail(e.target.value)}
            />
          </Field>
          <Field>
            <label>{messageLabel}</label>
            <textarea
              placeholder={messagePlaceholder}
              disabled={inputDisabled}
              onChange={e => setMessage(e.target.value)}
              value={message}
              rows={4}
            />
          </Field>
          <CheckboxField>
            <input
              type="checkbox"
              disabled={inputDisabled}
              onChange={e => setAgreed(e.target.checked)}
            />
            <CheckboxFieldText expanded={expanded}>
              <Html input={terms} />
            </CheckboxFieldText>
            <ExpandTermsButton>
              {!expanded && (
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault()

                    setExpanded(true)
                  }}
                >
                  {expandTerms}
                </a>
              )}
            </ExpandTermsButton>
          </CheckboxField>
        </Container>
        <Error hasError={submitStatus === SubmitStatus.SubmitFail}>
          <span className="h5">{submitFail}</span>
        </Error>
        <SubmitButtonContainer
          submitted={submitStatus === SubmitStatus.SubmitSuccess}
        >
          <h4>{submitSuccess}</h4>
          <button
            disabled={
              !agreed ||
              submitting ||
              submitStatus === SubmitStatus.SubmitSuccess ||
              !name ||
              !email
            }
            onClick={clickSubmit.bind(
              null,
              url,
              freeDemoOptIn,
              contactMeOptIn,
              campaign1OptIn,
              name,
              company,
              email,
              message,
              terms,
              setSubmitting,
              setSubmitStatus
            )}
          >
            {submitText}
          </button>
        </SubmitButtonContainer>
      </Wrapper>
      <AnchorLink ref={anchor}>
        <a href="#contact-us" className="button">
          {anchorButtonText}
        </a>
      </AnchorLink>
    </>
  )
}

export const typename = 'GraphCMS_ContactForm'

// TODO: Create fragment for pdfs
export const fragment = graphql`
  fragment defaultContactForm on GraphCMS_ContactForm {
    __typename
    id
    flatData {
      url
      anchorButtonText
      title
      description
      pdfs {
        fileName
        fileType
        url
        staticUrl
        metadata
      }
      secondDescription
      freeDemoOptInLabel
      contactMeOptInLabel
      campaign1OptInLabel
      nameLabel
      namePlaceholder
      companyLabel
      companyPlaceholder
      emailLabel
      emailPlaceholder
      messageLabel
      messagePlaceholder
      submitText
      submitSuccess
      submitFail
      terms
      expandTerms
    }
  }
`

export function renderContactForm(contactForm: any) {
  return <ContactForm key={contactForm.id} {...contactForm.flatData} />
}

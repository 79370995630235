import styled from 'styled-components'

type WrapperProps = {
  open: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  opacity: 0;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  transition: opacity 0.5s;

  ${({ open }) =>
    open &&
    `
        opacity: 1;
        pointer-events: all;

        ${Content} {
            transform: translateY(0);
        }
    `}
`

export const Content = styled.div`
  background-color: #000;
  margin: 15% auto;
  width: 80%;
  border: 0.125rem solid ${({ theme }) => theme.color.grayDark};
  max-width: 56.25rem;
  transform: translateY(-50%);
  transition: transform 0.5s;
`

import React from 'react'
import { graphql } from 'gatsby'
import Page from 'src/components/layout/Page'
import Meta from 'src/components/layout/Meta'
import Nav from 'src/components/common/Nav'
import Fullpage from 'src/components/common/Fullpage'
import Footer from 'src/components/common/Footer'
import Cookies from 'src/components/common/Cookies'
import CmsComponent from 'src/components/cms'

export default function Home({ pageContext, data }) {
  const frontPage = getFrontPage(data, pageContext.locale) || { content: [] }
  const site = getSite(data, pageContext.locale)

  return (
    <Page>
      <Meta pageContext={pageContext} />
      <Nav />
      <Fullpage
        logos={frontPage.logos}
        title={frontPage.title}
        subtitle={frontPage.subtitle}
        images={frontPage.images}
        puff={frontPage.puff}
      />
      {frontPage.content.map(content => {
        return <CmsComponent key={content.id} {...content} />
      })}
      <Footer text={site.footerText} images={site.footerImages} />
      <Cookies text={site.cookieText} buttonText={site.cookieButtonText} />
    </Page>
  )
}

export const query = graphql`
  fragment logoImage on GraphCMS_Asset {
    url
    imageFile {
      childImageSharp {
        fixed(width: 1100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }

  fragment frontPageImage on GraphCMS_Asset {
    url
    imageFile {
      childImageSharp {
        fixed(width: 753) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }

  fragment footerImage on GraphCMS_Asset {
    url
    metadata
    imageFile {
      childImageSharp {
        fixed(height: 34) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }

  query {
    cms {
      queryFrontPageContents {
        data {
          logo {
            sv {
              ...logoImage
            }
            en {
              ...logoImage
            }
          }
          subtitle {
            sv
            en
          }
          image {
            sv {
              ...frontPageImage
            }
            en {
              ...frontPageImage
            }
          }
          content {
            sv {
              __typename
              ...defaultPost
              ...defaultList
              ...defaultHero
              ...defaultContactForm
              ...defaultBanner
            }
            en {
              __typename
              ...defaultPost
              ...defaultList
              ...defaultHero
              ...defaultContactForm
            }
          }
        }
        flatData {
          puff {
            fileName
            fileType
            url
            staticUrl
            metadata
          }
        }
      }
      querySiteContents {
        data {
          footerText {
            sv
            en
          }
          footerImage {
            sv {
              ...footerImage
            }
            en {
              ...footerImage
            }
          }
          cookieText {
            sv
            en
          }
          cookieButtonText {
            sv
            en
          }
        }
      }
    }
  }
`

function getFrontPage(node, locale) {
  let content = node?.cms?.queryFrontPageContents[0]

  if (!content) {
    return null
  }

  const data = content.data

  if (!data) {
    return null
  }

  const logos = (data.logo && data.logo[locale || 'sv']) || []
  const images = (data.image && data.image[locale || 'sv']) || []
  const puff = content.flatData?.puff && content.flatData?.puff[0]

  return {
    logos: logos
      .map(image => image.imageFile?.childImageSharp?.fixed)
      .filter(image => !!image),
    subtitle: data.subtitle[locale || 'sv'],
    images: images
      .map(image => image.imageFile?.childImageSharp?.fixed)
      .filter(image => !!image),
    puff: puff ? puff.staticUrl : undefined,
    content: data.content[locale || 'sv'] || [],
  }
}

function getSite(node, locale) {
  let content = node?.cms?.querySiteContents[0]

  if (!content) {
    return null
  }

  const data = content.data

  if (!data) {
    return null
  }

  const footerImages =
    (data.footerImage && data.footerImage[locale || 'sv']) || []

  return {
    footerText: data.footerText[locale || 'sv'],
    footerImages: footerImages
      .map(image => {
        return {
          fixed: image.imageFile?.childImageSharp?.fixed,
          alt: image.metadata?.alt,
          url: image.metadata?.url,
        }
      })
      .filter(image => !!image.fixed),
    cookieText: data.cookieText[locale || 'sv'],
    cookieButtonText: data.cookieButtonText[locale || 'sv'],
  }
}

import React, { useRef } from 'react'
import { Wrapper, Content } from './style'

type ModalProps = {
  children?: any
  handleClose: Function
}

export default function Modal(props: ModalProps) {
  const ref = useRef(null)

  function click(e: Event) {
    if (!ref || !ref.current) {
      return
    }

    if (e.target === ref.current) {
      props.handleClose()
    }
  }

  return (
    <Wrapper ref={ref} open={props.open} onClick={click}>
      <Content>{props.children}</Content>
    </Wrapper>
  )
}

import React, { useState, useRef } from 'react'
import { graphql } from 'gatsby'
import { FixedObject } from 'gatsby-image'
import {
  Wrapper,
  Container,
  Left,
  LeftText,
  LeftImage,
  Right,
  RightImage,
} from './style'

export type BannerProps = {
  title: string
  url: string
  leftImages: FixedObject[]
  leftImageAlt: string
  rightImages: FixedObject[]
  rightImageAlt: string
}

export default function Banner(props: BannerProps) {
  const wrapper = (
    <Wrapper>
      <Container>
        <Left>
          <LeftText>
            <h2>{props.title}</h2>
          </LeftText>
          <LeftImage>
            {props.leftImages &&
              props.leftImages.map((fixed: FixedObject, i) => (
                <img
                  key={i}
                  src={fixed.src}
                  srcSet={fixed.srcSet}
                  alt={props.leftImageAlt}
                  data-aos="fade-down"
                />
              ))}
          </LeftImage>
        </Left>
        <Right>
          <RightImage>
            {props.rightImages &&
              props.rightImages.map((fixed: FixedObject, i) => (
                <img
                  key={i}
                  src={fixed.src}
                  srcSet={fixed.srcSet}
                  alt={props.rightImageAlt}
                />
              ))}
          </RightImage>
        </Right>
      </Container>
    </Wrapper>
  )

  if (props.url) {
    return <a href={props.url} target="_blank" style={{ textDecoration: 'none' }}>{wrapper}</a>
  }

  return wrapper
}

export const typename = 'GraphCMS_Banner'

export const fragment = graphql`
  fragment defaultBanner on GraphCMS_Banner {
    __typename
    id
    flatData {
      text
      url
      leftImage {
        url
        imageFile {
          childImageSharp {
            fixed(height: 400) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      leftImageAlt
      rightImage {
        url
        imageFile {
          childImageSharp {
            fixed(height: 400) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      rightImageAlt
    }
  }
`

export function renderBanner(banner: any) {
  const props = {
    title: banner.flatData.text,
    url: banner.flatData.url,
    leftImages: (banner.flatData?.leftImage || [])
      .map(image => {
        return image.imageFile?.childImageSharp?.fixed
      })
      .filter(image => !!image),
    leftImageAlt: banner.flatData.leftImageAlt,
    rightImages: (banner.flatData?.rightImage || [])
      .map(image => {
        return image.imageFile?.childImageSharp?.fixed
      })
      .filter(image => !!image),
    rightImageAlt: banner.flatData.rightImageAlt,
  }

  return <Banner key={banner.id} {...props} />
}

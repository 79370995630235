import styled from 'styled-components'
import { device } from 'src/styles/device'

export const Wrapper = styled.div`
  padding-top: 5.625rem;
  padding-bottom: 5.625rem;
  background-color: transparent;
  color: #fff;

  @media ${device.laptop} {
    padding-top: 8.125rem;
    padding-bottom: 8.125rem;
  }
`

export const Container = styled.div`
  max-width: 79.25rem;
  margin-left: auto;
  margin-right: auto;
`

export const ImageContainer = styled.div`
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  text-align: center;

  img {
    max-width: 56.25rem;
    width: 100%;
    margin-bottom: 2.5rem;
  }
`

export const TextContainer = styled.div`
  text-align: center;
`

export const VideoContainer = styled.div`
  text-align: center;
  margin-top: 3.125rem;
`

import styled from 'styled-components'
import { device } from 'src/styles/device'

type WrapperProps = {
  visible: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  pointer-events: none;
  transform: translateY(100%);
  transition: transform 0.25s;
  overflow: hidden;

  @media ${device.laptop} {
    display: table;
  }

  ${({ visible }) =>
    visible &&
    `
    pointer-events: all;
    transform: translateY(0);
  `}
`

export const Text = styled.div`
  display: block;
  vertical-align: middle;
  padding: 0.9375rem;

  @media ${device.laptop} {
    display: table-cell;
    padding: 2.1875rem;
  }

  & > div {
    max-height: 6.875rem;
    overflow-y: auto;
  }

  p {
    font-size: 0.875rem;

    @media ${device.laptop} {
      font-size: 1rem;
    }
  }

  p:last-child {
    padding-bottom: 0;
  }
`

export const ButtonContainer = styled(Text)`
  @media ${device.laptop} {
    text-align: right;
  }

  button {
    font-size: 0.875rem;
    padding: 0.875rem 1.25rem;
    white-space: nowrap;

    @media ${device.laptop} {
      text-align: right;
      font-size: 1.25rem;
      padding: 1.25rem 2.5rem;
    }
  }
`

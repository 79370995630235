import styled from 'styled-components'
import { device } from 'src/styles/device'

export const Wrapper = styled.div``

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: #fff;
  box-shadow: ${({theme}) => theme.shadow.banner};
  z-index: 0;

  @media ${device.mobileL} {
    padding: 0 1.75rem;
  }

  @media ${device.laptop} {
    flex-direction: row;
    padding: 0 0 0 3.125rem;
  }
`

export const Left = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  align-items: center;
  justify-content: space-around;
  padding: 2rem 1rem 4rem;
  overflow: hidden;

  @media ${device.mobileL} {
    flex-direction: row;
    gap: 0;
    padding-bottom: 2rem;
  }

  @media ${device.laptop} {
    width: 40%;
    padding: 3.75rem 1.875rem 3.75rem 2.5rem;
  }

  &:after {
    position: absolute;
    height: 100vw;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background-color: ${({ theme }) => theme.color.primaryAlt};
    border-radius: 50%;
    z-index: 1;

    @media ${device.mobileL} {
      height: initial;
      top: -6.25rem;
      right: 0;
      bottom: -6.25rem;
      left: 0;
    }

    @media ${device.tablet} {
      top: -18.75rem;
      bottom: -18.75rem;
    }
  }
`

export const LeftText = styled.div`
  position: relative;
  flex: 1;
  max-width: 17.1875rem;
  color: #000;
  z-index: 2;

  h2 {
    padding: 0;
    color: #000;
    font-family: ${({ theme }) => theme.font.banner};
    font-size: 1.15rem;
    font-weight: 800;
    line-height: 1.1;
    text-transform: uppercase;

    @media ${device.mobileS} {
      font-size: 1.5rem;
    }

    @media ${device.mobileM} {
      font-size: 1.8125rem;
    }

    @media ${device.laptop} {
      font-size: 2rem;
    }

    @media ${device.laptopL} {
      font-size: 2.625rem;
    }
  }
`

export const LeftImage = styled.div`
  position: relative;
  flex: 1;
  z-index: 2;

  img {
    width: 100%;
  }

  @media ${device.mobileL} {
    max-width: 25rem;
    padding-left: 0.9375rem;
  }
`

export const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1.5625rem 0.3125rem;

  @media ${device.laptop} {
    width: 50%;
    padding: 0 1.5625rem;
  }
`

export const RightImage = styled.div`
  max-width: 43.75rem;
  margin: 0 auto;

  img {
    flex: 1;
    max-width: 100%;
    max-height: 100%;
  }
`

import styled from 'styled-components'
import { device } from 'src/styles/device'

export const Wrapper = styled.div`
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  background-color: ${({ theme }) => theme.color.grayDarker};
  color: #fff;

  @media ${device.laptop} {
    padding-top: 8.125rem;
    padding-bottom: 8.125rem;
  }
`

export const Container = styled.div`
  max-width: 79.25rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    flex-direction: row;
  }
`

type ItemProps = {
  w: number
}

export const Item = styled.div<ItemProps>`
  flex: 1 1 auto;
  padding: 2.5rem;

  @media ${device.laptop} {
    width: ${({ w }) => w}%;
  }

  img {
    margin-right: 0.625rem;
    transform: translateY(0.9375rem);

    @media ${device.laptop} {
      transform: translateY(0.625rem);
    }
  }

  p {
    padding-bottom: 0;
  }
`

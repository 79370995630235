import React from 'react'
import { graphql } from 'gatsby'
import Html from 'src/components/common/Html'
import { Wrapper, Container, TextContainer, ImageContainer } from './style'
import { FixedObject } from 'gatsby-image'

enum Layout {
  ImageLeft = 'ImageLeft',
  ImageRight = 'ImageRight',
}

export type PostProps = {
  id: string
  title: string
  body: string
  images: FixedObject[]
  layout: Layout
}

export default function Post(props: PostProps) {
  return (
    <Wrapper>
      <Container layout={props.layout}>
        <ImageContainer>
          {props.images &&
            props.images.map((fixed: FixedObject, i) => {
              return (
                <img
                  key={i}
                  src={fixed.src}
                  srcSet={fixed.srcSet}
                  alt=""
                  data-aos={
                    props.layout === Layout.ImageRight
                      ? 'fade-left'
                      : 'fade-right'
                  }
                />
              )
            })}
        </ImageContainer>
        <TextContainer>
          <h2>{props.title}</h2>
          {props.body && (
            <div>
              <Html input={props.body} />
            </div>
          )}
        </TextContainer>
      </Container>
    </Wrapper>
  )
}

export const typename = 'GraphCMS_Post'

export const fragment = graphql`
  fragment defaultPost on GraphCMS_Post {
    __typename
    id
    flatData {
      title
      body
      image {
        url
        imageFile {
          childImageSharp {
            fixed(width: 525) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      layout
    }
  }
`

export function renderPost(post) {
  const props = {
    title: post.flatData?.title,
    body: post.flatData?.body,
    images: (post.flatData?.image || [])
      .map(image => {
        return image.imageFile?.childImageSharp?.fixed
      })
      .filter(image => !!image),
    layout: post.flatData?.layout,
  } as PostProps

  return <Post key={post.id} {...props} />
}

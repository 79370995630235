import React, { useState, useEffect } from 'react'
import { Wrapper, Text, ButtonContainer } from './style'
import Html from 'src/components/common/Html'

export default function Cookies({ text, buttonText }) {
  const [visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    try {
      const visible = localStorage.getItem('cookies')

      setVisible(visible !== 'true')
    } catch (e) {
      setVisible(true)
    }
  }, [])

  function clickAgree() {
    try {
      localStorage.setItem('cookies', 'true')
    } catch (e) {
      // Fail silently
      console.log(e)
    }

    setVisible(false)
  }

  return (
    <Wrapper visible={visible}>
      <Text>
        <Html input={text} />
      </Text>
      <ButtonContainer>
        <button onClick={clickAgree}>{buttonText}</button>
      </ButtonContainer>
    </Wrapper>
  )
}
